<template>
  <div class="hidden-sm-and-up home">
    <div class="header">
      <div class="title">
        智能化理财师
        <br />
        一站式综合作业与交流平台
      </div>
      <div class="btn" @click="download">
        下载i理财师
        <img class="arrow" src="@/images/arrow.png" />
      </div>
    </div>
    <div class="section1">
      <div class="title">i理财师简介</div>
      <div class="content">
        i理财师是美国纳斯达克全球市场上市集团——
        <b>普益集团（NASDAQ:PUYI）旗下的智能化理财师一站式综合作业与管理平台。</b>
        <br />
        i理财师是根据理财师线下服务场景，基于多元化的家庭金融资产配置需求，运用领先的金融科技技术，帮助理财师精准定位客户需求、高效追踪及管理客户的一站式理财师服务平台。
      </div>
      <img class="section1-intro" src="@/images/intros.png" />
      <div class="title">关于普益集团</div>
      <div class="content">
        普益集团（NASDAQ:PUYI）是一家基于金融资产配置，致力于为个人客户提供个性化财富管理服务、为理财师提供全方位支持服务、为机构客户提供多元化金融服务的综合金融科技服务集团。
        <br />
        针对理财师服务，普益集团为理财师搭建包括i理财师、普益TAMP平台、普益灵童、普益商学等系列服务平台，为理财师提供全面化的赋能服务。集团秉承买方顾问立场，携手理财师陪伴投资者，为投资者提供与其金融资产和需求匹配的全方位、个性化的资产配置服务。
      </div>
    </div>
    <div class="section2">
      <div class="title">普益TAMP平台</div>
      <div class="content">
        作为聚焦财富创造、可持续与传承的TAMP平台，普益TAMP平台坚持“专业平台，精英创业”理念，专业赋能财富工作室及理财师伙伴深耕万亿财富传承蓝海，成就创业梦想。
      </div>
      <div class="box">
        <img src="@/images/tamp.png" />
      </div>
    </div>
    <div class="section3">
      <div class="title">普益灵童</div>
      <div class="content">
        普益灵童是i理财师旗下的数字化家庭金融资产配置系统。普益灵童以现代资产组合理论为基础，运用大数据分析、云计算和智能化交易等手段，对接搭配种类丰富的组合产品，赋能理财师满足客户多元化的资产配置需求，为客户提供一站式的家庭金融资产配置规划服务。
      </div>
      <img src="@/images/lingtong1.png" />
    </div>
    <div class="section4">
      <div class="title">普益商学</div>
      <div class="content">
        普益商学是理财师金融教育专业赋能平台，致力于为理财师提供全方位的知识赋能、能力赋能和工具赋能，陪伴理财师成长。
      </div>
      <div class="section4-items">
        <div class="card card1">
          <div class="card-title">培育方案</div>
          <div class="card-content">
            体系化的金融知识课程
            <br />
            多元化的实战经验分享
            <br />
            精品化的行业大咖讲堂
          </div>
        </div>
        <div class="card card2">
          <div class="card-title">培育模式</div>
          <div class="card-content">
            标准化授课与综合性能力培养相结合
            <br />
            培训内容与业务实践相结合
            <br />
            认证和业绩提升相结合
            <br />
            线上线下相结合
          </div>
        </div>
      </div>
    </div>
    <div class="guide" v-show="modal">
      <div class="guide_img"></div>
      <div class="guide_know" @click="modal = false">我知道了</div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        modal: false,
      };
    },
    methods: {
      download() {
        const userAgent = window.navigator.userAgent;
        const system = userAgent.indexOf('iPhone') > -1 || userAgent.indexOf('iPad') > -1 ? 'iPhone' : 'Android';
        const isWechat =
          /MicroMessenger/i.test(navigator.userAgent.toLowerCase()) &&
          navigator.userAgent.indexOf('miniProgram') === -1;
        if (isWechat) {
          this.modal = true;
        } else {
          if (system === 'iPhone') {
            window.location.href = 'https://itunes.apple.com/cn/app/id1477006485?mt=8';
          } else {
            window.location.href = 'https://www.pyifp.com/download-apk';
          }
        }
      },
    },
  };
</script>
<style lang="less" scoped>
  .title {
    font-size: 30px;
    line-height: 30px;
    color: #b0824c;
    margin-bottom: 34px;
  }
  .content {
    font-size: 14px;
    color: #666666;
    line-height: 21px;
    text-align: justify;
  }
  .home {
    padding-top: 64px;
    .header {
      text-align: center;
      height: 487px;
      background-image: url('../../../images/bg-header-min.png');
      background-size: 100% 100%;
      padding-top: 51px;
      .title {
        font-size: 24px;
        color: #313345;
        line-height: 35px;
        margin-bottom: 30px;
      }
      .btn {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 40px;
        background: #313345;
        border-radius: 3px;
        font-size: 13px;
        color: #fff;
        .arrow {
          width: 20px;
        }
      }
    }
    .section1 {
      background: #f7f8fa;
      padding: 50px 20px 54px;
      &-intro {
        margin-top: 26px;
        margin-bottom: 56px;
        width: 100%;
      }
    }
    .section2 {
      padding: 50px 20px 0;
      .box {
        margin: 0 -20px;
        img {
          width: 100%;
        }
      }
    }
    .section3 {
      background: #f7f8fa;
      padding: 50px 20px 51px;
      img {
        width: 100%;
      }
    }
    .section4 {
      padding: 55px 20px 51px;
      &-items {
        display: flex;
        flex-direction: column;
        margin-top: 23px;
        .card {
          width: 100%;
          background-color: #f7f8fa;
          padding-top: 19px;
          padding-bottom: 17px;
          padding-left: 24px;
          &-title {
            font-size: 14px;
            line-height: 14px;
            font-weight: 600;
            margin-bottom: 15px;
          }
          &-content {
            font-size: 12px;
            line-height: 20px;
            color: #666666;
          }
          &.card1 {
            background-image: url(../../../images/ic1.png);
            background-repeat: no-repeat;
            background-position: right bottom;
            background-size: 70px;
            margin-bottom: 18px;
          }
          &.card2 {
            background-image: url(../../../images/ic2.png);
            background-repeat: no-repeat;
            background-position: right bottom;
            background-size: 84px;
          }
        }
      }
    }
  }
  .guide {
    height: 100%;
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    &_img {
      width: 275px;
      height: 200px;
      background-size: 100% 100%;
      background-image: url(../../../images/pic_guide.png);
      background-repeat: no-repeat;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
    }
    &_know {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 120px;
      height: 40px;
      color: #fff;
      border: 1px solid #fff;
      border-radius: 3px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>
