<template>
  <div class="home">
    <pc-layout />
    <mobile-layout />
  </div>
</template>

<script>
  import PcLayout from './pc/home';
  import MobileLayout from './mobile/home';
  export default {
    name: 'Home',
    data() {
      return {};
    },
    components: {
      PcLayout,
      MobileLayout,
    },
  };
</script>
