<template>
  <div>
    <!-- 移动端 -->
    <div class="hidden-sm-and-up footer-mobile">
      <div class="link-title">相关链接</div>
      <div class="links">
        <a class="item" href="https://www.puyiwm.com/">普益集团</a>
        <a class="item" href="https://www.puyiwm.com/product/education">普益商学</a>
        <a class="item" href="https://www.puyiwm.com/news/trends">普益资讯</a>
      </div>
      <div class="line"></div>
      <div class="qrcode">
        <div class="qrcode-item">
          <img src="@/images/qrcode1.png" />
          <div class="text">下载i理财师APP</div>
        </div>
        <div class="qrcode-item">
          <img src="@/images/qrcode2.png" />
          <div class="text">工作室加盟</div>
        </div>
      </div>
      <div class="copyright">
        Copyright © 2010-{{ year }} 普益财富
        <br />
        版权所有
        <a href="https://beian.miit.gov.cn/" v-if="isIfp">蜀ICP备11013043号-4</a>
        <a href="https://beian.miit.gov.cn/" v-else>蜀ICP备11013043号-1</a>
      </div>
    </div>
    <!-- PC -->
    <div class="hidden-xs-only footer-pc dark">
      <div class="container">
        <div class="footer-pc-content">
          <div class="link">
            <div class="link-title">相关链接</div>
            <div class="link-links">
              <a href="https://www.puyiwm.com/" target="_blank">普益集团</a>
              ｜
              <a href="https://www.puyiwm.com/product/education" target="_blank">普益商学</a>
              ｜
              <a href="https://www.puyiwm.com/news/trends" target="_blank">普益资讯</a>
            </div>
            <div class="link-copyright">
              Copyright © 2010-{{ year }} 普益财富 版权所有
              <a href="https://beian.miit.gov.cn/" target="_blank" v-if="isIfp">蜀ICP备11013043号-4</a>
              <a href="https://beian.miit.gov.cn/" target="_blank" v-else>蜀ICP备11013043号-1</a>
            </div>
          </div>
          <div class="qrcode">
            <div class="qrcode-item">
              <img src="@/images/qrcode1.png" />
              <div class="text">下载i理财师APP</div>
            </div>
            <div class="qrcode-item">
              <img src="@/images/qrcode2.png" />
              <div class="text">工作室加盟</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Footer',
    data() {
      return {
        year: new Date().getFullYear(),
        isIfp: window.location.host.indexOf('pyifp.com') > -1,
      };
    },
  };
</script>
