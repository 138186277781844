<template>
  <div class="hidden-xs-only pc">
    <div class="header">
      <div class="container">
        <div class="header-title">
          智能化理财师
          <br />
          一站式综合作业与交流平台
        </div>
        <div class="header-btn" @click="download">
          下载i理财师
          <img class="arrow" src="@/images/arrow.png" />
        </div>
      </div>
    </div>
    <div class="section1 dark">
      <div class="container">
        <div class="title">i理财师简介</div>
        <div class="content">
          i理财师是美国纳斯达克全球市场上市集团——
          <b>普益集团（NASDAQ:PUYI）旗下的智能化理财师一站式综合作业与管理平台。</b>
          <br />
          i理财师是根据理财师线下服务场景，基于多元化的家庭金融资产配置需求，运用领先的金融科技技术，帮助理财师精准定位客户需求、高效追踪及管理客户的一站式理财师服务平台。
        </div>
        <div class="section1-intro">
          <img src="@/images/intro1.png" />
          <img src="@/images/intro2.png" />
          <img src="@/images/intro3.png" />
        </div>
        <div class="title">关于普益集团</div>
        <div class="content">
          普益集团（NASDAQ:PUYI）是一家基于金融资产配置，致力于为个人客户提供个性化财富管理服务、为理财师提供全方位支持服务、为机构客户提供多元化金融服务的综合金融科技服务集团。
          <br />
          针对理财师服务，普益集团为理财师搭建包括i理财师、普益TAMP平台、普益灵童、普益商学等系列服务平台，为理财师提供全面化的赋能服务。集团秉承买方顾问立场，携手理财师陪伴投资者，为投资者提供与其金融资产和需求匹配的全方位、个性化的资产配置服务。
        </div>
      </div>
    </div>
    <div class="section2 container">
      <div class="title">普益TAMP平台</div>
      <div class="content">
        作为聚焦财富创造、可持续与传承的TAMP平台，普益TAMP平台坚持“专业平台，精英创业”理念，专业赋能财富工作室及理财师伙伴深耕万亿财富传承蓝海，成就创业梦想。
      </div>
      <div class="section2-tamp">
        <img class="tamp1" src="@/images/tamp1.png" />
        <img class="tamp2" src="@/images/tamp2.png" />
      </div>
    </div>
    <div class="section3 dark">
      <div class="container">
        <div class="title">普益灵童</div>
        <div class="content">
          普益灵童是i理财师旗下的数字化家庭金融资产配置系统。普益灵童以现代资产组合理论为基础，运用大数据分析、云计算和智能化交易等手段，对接搭配种类丰富的组合产品，赋能理财师满足客户多元化的资产配置需求，为客户提供一站式的家庭金融资产配置规划服务。
        </div>
        <div class="section3-lingtong">
          <img src="@/images/lingtong.png" />
        </div>
      </div>
    </div>
    <div class="section4 container">
      <div class="title">普益商学</div>
      <div class="content">
        普益商学是理财师金融教育专业赋能平台，致力于为理财师提供全方位的知识赋能、能力赋能和工具赋能，陪伴理财师成长。
      </div>
      <div class="section4-items">
        <div class="card card1">
          <div class="card-title">培育方案</div>
          <div class="card-content">
            体系化的金融知识课程
            <br />
            多元化的实战经验分享
            <br />
            精品化的行业大咖讲堂
          </div>
        </div>
        <div class="card card2">
          <div class="card-title">培育模式</div>
          <div class="card-content">
            标准化授课与综合性能力培养相结合
            <br />
            培训内容与业务实践相结合
            <br />
            认证和业绩提升相结合
            <br />
            线上线下相结合
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    methods: {
      download() {
        const isMac = /macintosh|mac os x/i.test(navigator.userAgent);
        if (isMac) {
          window.location.href = 'https://itunes.apple.com/cn/app/id1477006485?mt=8';
        } else {
          window.location.href = 'https://www.pyifp.com/download-apk';
        }
      },
    },
  };
</script>
<style lang="less" scoped>
  @primary-color: #b0824c;
  .pc {
    padding-top: 90px;
    .container {
      max-width: 1170px;
      padding: 0 15px;
      margin: 0 auto;
      .title {
        font-size: 38px;
        color: @primary-color;
        margin-bottom: 63px;
      }
      .content {
        font-size: 16px;
        line-height: 42px;
        color: #666666;
      }
    }

    .dark {
      background-color: #f7f8fa;
    }
    .header {
      height: 602px;
      background-image: url('../../../images/bg-header.png');
      background-size: cover;
      padding-top: 156px;
      &-title {
        font-size: 56px;
        color: #313345;
        line-height: 70px;
        height: 132px;
      }
      &-btn {
        margin-top: 95px;
        padding: 21px 39px;
        background: #313345;
        border-radius: 6px;
        font-size: 22px;
        color: #fff;
        display: inline-block;
        cursor: pointer;
        &:hover {
          background: #43465e;
        }
        &.arrow {
          width: 31px;
        }
      }
    }
    .section1 {
      padding-top: 120px;
      padding-bottom: 124px;
      &-intro {
        margin-top: 57px;
        margin-bottom: 120px;
        display: flex;
        justify-content: space-between;
      }
    }
    .section2 {
      padding-top: 114px;
      &-tamp {
        text-align: center;
        .tamp1 {
          width: 882px;
        }
        .tamp2 {
          width: 1024px;
        }
      }
    }
    .section3 {
      padding-top: 120px;
      padding-bottom: 102px;

      &-lingtong {
        margin-top: 108px;
        text-align: center;
        img {
          width: 1044px;
        }
      }
    }
    .section4 {
      padding-top: 122px;
      padding-bottom: 131px;
      &-items {
        display: flex;
        justify-content: space-between;
        margin-top: 61px;
        .card {
          width: 530px;
          height: 222px;
          background-color: #f7f8fa;
          padding-top: 32px;
          padding-left: 49px;
          &-title {
            font-size: 24px;
            line-height: 24px;
            font-weight: 600;
            margin-bottom: 26px;
          }
          &-content {
            font-size: 16px;
            line-height: 30px;
            color: #666666;
          }
          &.card1 {
            background-image: url(../../../images/ic1.png);
            background-repeat: no-repeat;
            background-position-x: right;
          }
          &.card2 {
            background-image: url(../../../images/ic2.png);
            background-repeat: no-repeat;
            background-position-x: right;
          }
        }
      }
    }
  }
</style>
