import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Contact from '../views/Contact/index.vue';
import Show from '../views/Show/index.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '走进i理财师 - 普益基金',
      showHeader: true,
      showFooter: true,
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      title: '联系我们',
      showHeader: true,
      showFooter: true,
    },
  },
  {
    path: '/show',
    name: 'Show',
    component: Show,
    meta: {
      title: '功能展示',
      showHeader: true,
      showFooter: true,
    },
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('../views/Privacy/index.vue'),
    meta: {
      title: '隐私政策',
      showFooter: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    window.scrollTo(0, 0);
  },
});

export default router;
