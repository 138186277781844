<template>
  <div id="app">
    <Header v-if="$route.meta && $route.meta.showHeader" />
    <router-view />
    <Footer v-if="$route.meta && $route.meta.showFooter" />
  </div>
</template>
<script>
  import Header from './views/compontents/header';
  import Footer from './views/compontents/footer';
  export default {
    components: {
      Header,
      Footer,
    },
  };
</script>
