<template>
  <div>
    <!-- 移动端 -->
    <div class="hidden-sm-and-up header-mobile">
      <div class="box">
        <img class="logo" src="@/images/logo.png" />
        <div class="menu">
          <img class="icon" @click="showMenu" src="@/images/ic_menu.png" />
        </div>
      </div>
    </div>
    <!-- PC -->
    <div class="hidden-xs-only header-pc">
      <div class="navbar container">
        <img class="navbar-logo" src="@/images/logo.png" />
        <div class="navbar-menu">
          <div
            v-for="(nav, i) in navs"
            :key="i"
            class="item"
            :class="{
              active: nav.active,
            }"
            @click="route(nav)"
          >
            <div>{{ nav.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <el-drawer title="menu" :with-header="false" :visible.sync="drawer" direction="ltr" :size="250" :show-close="false">
      <div class="drawer-container">
        <div v-for="(nav, i) in navs" :key="i" class="item" :class="{ active: nav.active }" @click="route(nav)">
          <img :src="require(`@/images/ic_nav_${i + 1}.png`)" :style="{ width: nav.w + 'px', height: nav.h + 'px' }" />
          {{ nav.name }}
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        navs: [
          { name: '首页', url: '/', w: '21', h: '19', active: false },
          { name: '功能展示', url: '/show', w: '18', h: '21', active: false },
          { name: '联系我们', url: '/contact', w: '19', h: '19', active: false },
        ],
        drawer: false,
      };
    },
    methods: {
      route(nav) {
        if (location.pathname === nav.url) return;
        this.$router.replace(nav.url);
        this.drawer = false;
      },
      showMenu() {
        this.drawer = true;
      },
      activeNav(path) {
        const nav = this.navs.find(v => v.url === path);
        nav.active = true;
      },
    },
    created() {
      // 初始化导航
      this.activeNav(location.pathname);
    },
    watch: {
      $route(to, from) {
        this.navs.forEach(n => (n.active = false));
        this.activeNav(to.path);
      },
    },
  };
</script>
<style lang="less" scoped>
  @primary-color: #b0824c;

  .header-pc {
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 1;
    .navbar {
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      &-logo {
        width: 142px;
      }
      &-menu {
        display: flex;
        height: 100%;
        .item {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 71px;
          cursor: pointer;
          &:hover {
            color: #b0824c;
          }
          &.active {
            color: @primary-color;
            &::after {
              content: '';
              background-color: @primary-color;
              height: 3px;
              position: absolute;
              bottom: 0;
              width: 100%;
            }
          }
        }
      }
    }
  }
  .header-mobile {
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
    height: 64px;
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 1;

    .box {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      .logo {
        width: 99px;
      }
      .menu {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        padding-left: 16px;
        .icon {
          width: 25px;
        }
      }
    }
  }
  .drawer-container {
    padding-top: 78px;
    padding-left: 23px;
    background-color: #fff;
    color: #313345;
    height: 100%;
    .item {
      font-size: 18px;
      font-weight: 600;
      display: flex;
      align-items: center;
      margin-bottom: 47px;
      img {
        margin-right: 12px;
      }
      &.active {
        color: #b0824c;
      }
    }
  }
</style>
